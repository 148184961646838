import React from 'react';
import HoverAnimation from "../components/hoverAnimation";

const Research = () => {
    const publications = [
        {
            title: "IMUGPT2.0 - Language-Based Cross-Modality Transfer for Sensor-Based Human Activity Recognition",
            timeline: "Oct 2023 - Feb 2024",
            description: "Augmented prompt engineering for GPT-3.5, GPT-4, LLaMa 2, Palm 2, and Gemini to generate 1000 text descriptions for over 75 activities. Quantized a 70 billion parameter LLaMa 2 model for deployment on 2 A10 GPUs. Deployed models and ran over 1000 experiments on 12 Nvidia GPU-based servers.",
            tech: ["GPT-3.5", "GPT-4", "Bard", "LLaMa", "Gemini", "Human Activity Recognition"],
            href: "https://arxiv.org/abs/2402.01049",
            status: "Preprint"
        }
    ]

    const research = [
        {
            title: "Human Activity Recognition simulation framework using Large Language Models (Title TBD)",
            timeline: "Aug 2024 - Dec 2024 (expected)",
            description: "Creating a simulation framework using Large Language Models to generate realistic virtual training data for Human Activity Recognition using Smart Home and Wearable sensors.",
            tech: ["Large Language Models", "Human Activity Recognition", "GPT-4o Mini", "LangChain", "Interactive Simulacra", "HabitatAI"],
            href: "#"
        },
        {
            title: "Implications of Large Language Models Memorizing Human Activity Recognition Datasets (Title TBD)",
            timeline: "Apr 2024 - Nov 2024 (expected)",
            description: "Assessing and investigating the possibility of Large Language Models memorizing publicly available HAR datasets.",
            tech: ["Large Language Models", "Human Activity Recognition", "GPT", "Gemini"],
            href: "#"
        },
        {
            title: "PPO and Knowledge Distillation",
            timeline: "Dec 2023 - May 2024",
            description: "Examining the interaction between Proximal Policy Optimization (PPO) and Knowledge Distillation in the context of language models.",
            tech: ["RLHF", "Proximal Policy Optimization", "Knowledge Distillation", "Language Models"],
            href: "#"
        },
        {
            title: "Optimizing Algorithmic Options Trading using Machine Learning",
            timeline: "Aug - Dec 2023",
            description: "Developed a classification model using to take discretionary decisions for an intraday short straddle strategy. Achieved 32% P&L improvement by training ML models to take discretionary decisions for a short straddle strategy.",
            tech: ["Random Forest", "XGBoost", "LightGBM", "CatBoost", "Options Trading", "Algorithmic Trading"],
            href: "#"
        },
        {
            title: "Recommender Systems for Movie Streaming Platforms",
            timeline: "Aug - Dec 2023",
            description: "Developed classification and regression models for movie recommender systems with an RMSE of 0.84.",
            href: "https://hrudhai98.github.io/cs7650-ml-project/",
            tech: ["Singular Value Decomposition", "Random Forest", "Graph Neural Networks"]
        },
    ]

    return (
        <div className="col-span-12 lg:col-span-8 ">
            <div className="lg:rounded-2xl bg-pure-white dark:bg-[#111111] px-8 lg:px-0">
                <div data-aos="fade" className="aos-init">
                    <div className="container px-4 sm:px-5 md:px-10 lg:px-14">
                        <div className={"pt-12 pb-6"}>
                            <h2 className="after-effect after:left-72">
                                Publications
                            </h2>
                            <div className="flex flex-col gap-y-2 my-4">
                                {publications.map((item, index) => (
                                    <HoverAnimation opacity={0.4} key={index} className={"mb-6 rounded-xl bg-dark-gray cursor-pointer"}>
                                        <HoverAnimation className="m-0.5 p-4 rounded-xl bg-white dark:bg-[#1D1D1D]">
                                            <div className={"gap-6 flex flex-col"}>
                                                <div className={"flex flex-col justify-between gap-2"}>
                                                    {/*<div className={"w-fit cursor-pointer"}>*/}
                                                    {/*    <div key={index} className="rounded-2xl bg-gradient-to-br from-green-400/70 to-blue-300/70 text-sm text-white px-2 py-0.5">*/}
                                                    {/*        <span className={"flex gap-2 items-center"}>{item.status} <Link /></span>*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                    <div className={"relative pointer-events-none w-fit"}>
                                                        <a href={item.href} target={"_blank"} rel="noreferrer" className={`${item.href !== '#' ? 'flex' : 'hidden'} group items-center justify-between gap-2 text-sm rounded-full bg-black dark:bg-white text-white dark:text-black px-2 pointer-events-auto`}>
                                                            {item.status}
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                                            </svg>
                                                        </a>
                                                    </div>
                                                    <div className={"flex justify-between items-center"}>
                                                        <div className="text-lg dark:text-white font-medium">
                                                            {item.title}
                                                        </div>
                                                        <div className="text-xs text-gray-lite dark:text-gray text-end">
                                                            {item.timeline}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={""}>
                                                    {item.description}
                                                </div>
                                                <div className={"flex flex-wrap gap-x-2 gap-y-2"}>
                                                    {item.tech.map((tech, index) => (
                                                        <div key={index} className="rounded-2xl bg-gradient-to-br from-hrudhai-blue/70 to-hrudhai-purple/70 text-sm text-white px-2 py-0.5">
                                                            {tech}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </HoverAnimation>
                                    </HoverAnimation>
                                ))}
                            </div>
                        </div>
                        <div className={"pb-12 pt-6"}>
                            <h2 className="after-effect after:left-52">
                                Research
                            </h2>
                            <div className="flex flex-col gap-y-2 my-4">
                                {research.map((item, index) => (
                                    <HoverAnimation opacity={0.4} key={index} className={"mb-6 rounded-xl bg-dark-gray"}>
                                        <HoverAnimation className="m-0.5 p-4 rounded-xl bg-white dark:bg-[#1D1D1D]">
                                            <div className={"gap-6 flex flex-col"}>
                                                <div className={"flex justify-between items-center"}>
                                                    <div className="text-lg dark:text-white font-medium">
                                                        {item.title}
                                                    </div>
                                                    <div className="text-xs text-gray-lite dark:text-gray text-end">
                                                        {item.timeline}
                                                    </div>
                                                </div>
                                                <div className={""}>
                                                    {item.description}
                                                </div>
                                                <div className={"flex flex-wrap gap-x-2 gap-y-2"}>
                                                    {item.tech.map((tech, index) => (
                                                        <div key={index} className="rounded-2xl bg-gradient-to-br from-hrudhai-blue/70 to-hrudhai-purple/70 text-sm text-white px-2 py-0.5">
                                                            {tech}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </HoverAnimation>
                                    </HoverAnimation>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Research;